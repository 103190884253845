import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { stackoverflowDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export const Timeline = [
	{
		title: "Coding since 2017",
		subtitle: <>J'ai commencé sérieusement la programmation en 2017, en faisant des petits projets comme des plugins Minecraft, ou des addons Garry's Mod.</>,
		id: 0,
	},
    {
        title: "Garry's Mod servers & Addons (2017-2021)",
        subtitle: <>Je me suis donc mis à faire quelques addons pour des serveurs Garry's Mod. Mais j'ai arrêté car cela ne me plaisait plus.</>,
        id: 1,
    },	
	{
		title: "Discord Bots: (2018-2022)",
		subtitle: <>J'ai créé quelques robots pour l'application Discord.</>,
		id: 4,
	},
	{
		title: "Counter-Strike/SourceMod: Network (2021-2023)",
		subtitle: <>J'ai toujours aimé <a href="https://developer.valvesoftware.com/wiki/Source">le moteur Source</a>. Quand j'ai commencé à faire du bhop, j'ai décidé de créer mes propres serveurs de jeux.</>,
		id: 2,
	},	
	{
		title: "EquationSolver (2022)",
        subtitle: <>J'ai eu l'occasion, en cours de maths, de créer un logiciel qui résoud les équations à deux inconnues grâce à la formule de Cramer.</>,
		id: 5,
	},
	{
		title: "seenkid.dev (2022-Present)",
		subtitle: <>Il était important que je décide enfin de me faire un site web adapté. J'ai donc décidé, en récupérant certains de mes vieux bouts de code et en les remettant au gout du jour, de refaire ce site web.</>,
		id: 3,
	},
	{
		title: "Flipper Zero Bad Usb Scripts (2023-present)",
        subtitle: <>J'ai eu l'occasion, en prennant mon flipper zero, de faire quelques scripts pour sa fonction bad keyboard. Mes Scripts sont désormais populaires.</>,
		id: 6,
	},
	{
		title: "CroustyCloud (2024-present)",
        subtitle: <>Je travaille avec Romain et Théo chez CroustyCloud, afin de proposer des serveurs privés virtuels de qualité.</>,
		id: 7,
	},
]


export const TimelineData = [
	{
		title: "Hello World",
		subtitle: "",
		text: <>
        J'ai réellement commencé la programmation en créant des petits trucs sur Gmod et Minecraft
			<br/><br/>
			<SyntaxHighlighter language="js" style={stackoverflowDark} wrapLongLines >
				print("hello world");
			</SyntaxHighlighter>
			<br/>
		</>,
		id: 0,
	},
    {
        title: "Nomos Roleplay",
        subtitle: "",
        text: <><a href="https://nomos-roleplay.com">Nomos Roleplay</a> est un serveur DarkRP que j'ai maintenu pendant longtemps avant de l'arrêter.</>,
        id: 1,
    },
    {
    title: "Addons creations",
    subtitle: "",
    text: <>J'ai créé et modifié plusieurs addons pour mon serveur : Nomos-Wallet, Nomos-CarSeller, Nomos-PoliceSystem, etc...
    <SyntaxHighlighter language="lua" style={stackoverflowDark} showLineNumbers wrapLongLines >
        {`hook.Add("PlayerInitialSpawn","Nomos:Police:SystemArgent::PlayerInitialSpawn",function(ply)

if not file.Exists("Nomos/Police/" .. ply:SteamID64() .. ".txt","DATA") then

            if ply:IsPlayer() then
            file.Write("Nomos/Police/" .. ply:SteamID64() .. ".txt", "Recrue")
        end
    end
end)

util.AddNetworkString("Nomos:PoliceSystem::setgrade")
util.AddNetworkString("Nomos:Argentsalle::setgrade")
util.AddNetworkString("Nomos:PoliceSystem::inforegarde")
util.AddNetworkString("Nomos:Argentsalle::inforegarde")
util.AddNetworkString("Nomos:PoliceSystem::Info")
util.AddNetworkString("Nomos:Argentsalle::Info")`}
    </SyntaxHighlighter>
    Voici une partie du plugin Nomos-PoliceSystem.</>,
    id: 1,
    },
	{
		title: "Mon premier plugin SourcePawn",
		subtitle: "",
        text: <>J'ai eu la chance de pouvoir créer mon propre réseau de serveurs de jeu Counter-Strike. C'était beaucoup de travail et tout a très bien fonctionné pendant 2 bonnes années, mais j'ai dû arrêter. Les serveurs sont aujourd'hui toujours ouverts en collaboration avec <a href="https://shiftek.fr/gaming">Shiftek Gaming</a>, bien que mis de côté car non prioritaires.

		<SyntaxHighlighter language="pawn" style={stackoverflowDark} showLineNumbers wrapLongLines >
            {`#pragma semicolon 1

#define DEBUG

#define PLUGIN_AUTHOR "SeenKid"
#define PLUGIN_VERSION "0.01a"

#include <sourcemod>
#include <sdktools>

#pragma newdecls required

public Plugin myinfo =
{
    name = "SeenKid's Shavit Lines",
    author = PLUGIN_AUTHOR,
    description = "SeenKid's Shavit lines - Find your route on the ground.",
    version = PLUGIN_VERSION,
    url = "https://github.com/SeenKid/shavit-line"
};

public void OnPluginStart()
{

}
            `}
		</SyntaxHighlighter>
        J'ai créé le plugin nommé "Shavit Lines". Ce plugin permet de prendre la route d'un replaybot et de l'afficher sur le sol afin d'aider les jours à mémoriser la route qui les amène au record.		<SyntaxHighlighter language="cpp" style={stackoverflowDark} showLineNumbers wrapLongLines >

		</SyntaxHighlighter>
						  </>,
        id: 2,
	},
	{
		title: "Shavit-Styles",
		subtitle: "",
		text: <>J'ai également créé plein de styles originaux pour le bhop (Shavitush's bhop timer)</>,
        id: 2,
	},
    {
		title: "Le site originel",
		subtitle: "La première version.",
		text: <>
		Le projet original était un site Web Wordpress, mais je voulais faire quelque chose de mieux. J'ai donc repris des débris de code de mes années précédentes, et j'ai tout assemblé pour en faire le site web que vous voyez aujourd'hui.<br/>
		</>,
        id: 3,
	},
	{
		title: "SafeBot",
		subtitle: "A moderation bot",
		text: <>Safebot était un bot de modération développé par mes soins. Il était en activité de septembre 2018 jusqu'en octobre 2019. </>,
		id: 4,
	},
	{
		title: "WellBot",
		subtitle: <></>,
        text: <>Wellbot était un bot français développé originellement par WellTag qui contenait plusieurs petits jeux pour amuser les membres de vos serveurs ainsi que quelques commandes de modération. Le projet a été abandonné car difficile à maintenir suite aux choix de librairie faite par le développeur principal.</>,
		id: 4,
	},
    {
        title: "EquationSolver",
        subtitle: <></>,
        text: <><a href="https://github.com/seenkid/equationsolver">EquationSolver</a> étiat un projet pour résoudre des équations facilement à l'aide de la formule de Cramer.</>,
        id: 5,
    },
	{
        title: "BadUSB Scripts ",
        subtitle: <></>,
        text: <><a href="https://github.com/SeenKid/flipper-zero-bad-usb">flipper-zero-bad-usb repository</a></>,
        id: 6,
    },
	{
        title: "CroustyCloud",
        subtitle: <></>,
        text: <><a href="https://croustycloud.fr/-seenkid">Est un hébergeur de serveurs privés virtuels (VPS). Nous sommes une petite équipe, visant à toujours proposer la meilleure qualité possible aux prix les plus bas pour satisfaire au mieux nos clients.</a></>,
        id: 7,
    },

	//{
	//	title: "",
	//	subtitle: <></>,
	//	text: <></>,
	//	id: ,
	//}},

]
