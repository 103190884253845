import React from 'react'

export const ProjectsData = [
	{
		title: "CroustyCloud",
		subtitle: <>Hébergeur VPS</>,
		link: "https://croustycloud.fr/-seenkid"
	},
	{
		title: "Nicolux Communauté",
		subtitle: <>Administrateur Discord chez Nicolux</>,
		link: "https://discord.gg/de3n5ew5AM"
	},
	{
		title: "Modération Speedrun.com",
		subtitle: <>sur Iblis & Depression the game</>,
		link: "#"
	},
	{
		title: "Shiftek Gaming",
		subtitle: <>Communauté Gaming de notre prestataire</>,
		link: "https://shiftek.fr/vms/@bhop"
	},
	{
		title: "Flipper Zero: Bad USB Scripts",
		subtitle: <>Scripts pour le BadUSB</>,
		link: "https://github.com/SeenKid/flipper-zero-bad-usb"
	},
	{
		title: "Bitmining (abandonné)",
		subtitle: <>Minage de crypto-monnaies</>,
		link: "https://croustycloud.fr/-seenkid"
	},
    {
		title: "EquationSolver (abandonné)",
        subtitle: <>Résolveur d'équations.</>,
		link: "https://github.com/seenkid/equationsolver",
	},
    {
		title: "Blog (abandonné)",
        subtitle: <>Blog personnel</>,
        link: "/blog",
	},
	{
		title: "Australis-Hosting (abandonné)",
		subtitle: <>Hébergement de serveurs de jeux</>,
		link: "https://manager.australis-hosting.com"
	},
	{
		title: "Cooper (abandonné)",
		subtitle: <>Cheat Minecraft 1.8.8</>,
		link: "#"
	},
	{
		title: "Npanel (abandonné)",
		subtitle: <>Panel GMOD</>,
		link: "#"
	},
]

