import React from 'react'

export const TopbarData = [
	{
		title: "Accueil",
		link: "/",
		key: "1"
	},
	// {
	// 	title: "BHOP Resources",
	// 	link: "/resources",
	// 	key: "1"
	// },
	{
		title: "A propos",
		link: "/about",
		key: "1"
	},
	{
		title: "Projets et liens",
		link: "/projects",
		key: "1"
	},
	// {
	// 	title: "How To Setup CSS Lan",
	// 	link: "/lan/",
	// 	key: "1"
	// },
	// {
	// 	title: "CS:S Server Builder",
	// 	link: "/builder/",
	// 	key: "1"
	// },
    // {
    //     title: "Blog",
    //     link: "/blog",
    //     key: "2"
    // },
	// {
	// 	title: "FastDL",
	// 	link: "/server/maps/.index.php",
	// 	key: "1"
	// },
	{
		title: "Github",
		link: "https://github.com/SeenKid",
		key: "2"
	},
	{
		title: "Gamebanana",
    	link: "https://gamebanana.com/members/1981210",
		key: "2"
	}
]
