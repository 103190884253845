import React from 'react';
import { TopbarData } from './TopbarData';
import '../CSS/Topbar.css';

function Topbar() {

	return(
		<>
		<div className="header">
			<div className="logotext">
                ░██████╗███████╗███████╗███╗░░██╗██╗░░██╗██╗██████╗░<br/>
                ██╔════╝██╔════╝██╔════╝████╗░██║██║░██╔╝██║██╔══██╗<br/>
                ╚█████╗░█████╗░░█████╗░░██╔██╗██║█████═╝░██║██║░░██║<br/>
                ░╚═══██╗██╔══╝░░██╔══╝░░██║╚████║██╔═██╗░██║██║░░██║<br/>
                ██████╔╝███████╗███████╗██║░╚███║██║░╚██╗██║██████╔╝<br/>
                ╚═════╝░╚══════╝╚══════╝╚═╝░░╚══╝╚═╝░░╚═╝╚═╝╚═════╝░<br/>
			</div>
			<div className="TopbarContainer">
				<div className="TopbarList">
					{TopbarData.map((val) => {
						return(
							<>
							<a
								key={val.title}
								className="tab"
								id={window.location.pathname === val.link ? 'active' : ''}
								href={val.link}
								target={externalLink(val.key)}
							>
								{val.title}
							</a>
							&ensp;
							</>
						)
					})}
				</div>
			</div>
		</div>

		</>
	)
}

function externalLink(key)
{
	if(key == 1)
		return "";
	else if (key == 2)
		return "_blank";
}
export default Topbar;
