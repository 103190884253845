import React from 'react';
import '../CSS/404.css';

export default HTTP404;

function HTTP404() {
	return(
		<>	
		<div className="fourofour">

			<div className="container">
		
					<h2>Merde alors !</h2>
 <br/>
				On dirait que tu es arrivé sur une page <b>qui n'exite pas 👀</b>.<br/><br/>
				Peut-être que la page en question n'existe plus 🚧<br/>
			</div>
		</div>
		</>
	)	
}


