import React from 'react';
import '../CSS/AboutMe.css';
import { Timeline, TimelineData } from './AboutMeData';

export default AboutMe;

function AboutMe() {
    const [showData, setShowData] = React.useState(Array(TimelineData.length).fill(false));

    const [animate, setAnimate] = React.useState(Array(TimelineData.length).fill('FadeOut'));

    let mousePosition = {
        x: 0,
        y: 0
    };

    const mouseDown = (e) =>{
        mousePosition.x = e.screenX;
        mousePosition.y = e.screenY;
    }

    const handleExpand = (e, i) => {
        if (
            mousePosition.x !== e.screenX &&
            mousePosition.y !== e.screenY
        )
            return;
        const ani = [...animate];
        ani[i] === '' ? ani[i] = 'FadeOut' : ani[i] = '';
        setAnimate(ani)
        if(animate[i] === "")
        {
            setTimeout(() => {
                const data = [...showData];
                data[i] = !data[i];
                setShowData(data);
            }, 295);
        }
        else{
            const data = [...showData];
            data[i] = !data[i];
            setShowData(data);
        }
    };
	return(
		<>
		<div className="container_aboutme">
            <div className="timeline">
                <h2>Qui suis-je ?</h2>
                C'est une excellente question, et je suis content que tu me le demandes ! 🚀<br/><br/>

                Je suis Yann ! 🇨🇭<br/>
                Développeur en alternance depuis bientôt 4 ans. J'aime beaucoup l'informatique, c'est quelque chose qui m'a toujours passionné. 💪
                 <br/><br/><br/><br/>

            </div>
            <div className="timeline">
                <h2>Timeline de mes projets 📌</h2>
                <sub>En parlant de projets, voici, ci-dessous, une liste (non exhaustive) des projets sur lesquels j'ai pu travailler ces quelques dernières années.</sub>
                {Timeline.map((events)=>{
                    return(
                        <>
                        <a onMouseDown={mouseDown} onClick={(e) => handleExpand(e, events.id)} >
                            <div className="timeline_outside">
                                <div className="timeline_container">
                                    <div className='timeline_title'>
                                        {events.title}
                                    </div>
                                    <div className='timeline_subtitle'>
                                        {events.subtitle}
                                    </div>
                                    {showData[events.id] && TimelineData.filter(x => x.id==events.id).map((val)=>{
                                        return(
                                        <>
                                            <div className="timeline_event" animate={animate[events.id]}>
                                                <div className="timeline_event_title">
                                                    <h2>{val.title}</h2>
                                                </div>
                                                <div className="timeline_event_subtitle">
                                                    {val.subtitle}
                                                </div>
                                                <div className='timeline_event_content'>
                                                    {val.text}
                                                </div>
                                            </div>
                                        </>
                                        )
                                    })}

                                </div>
                            </div>
                        </a>
                        </>
                    )
                })}
            </div>
		</div>
		</>
	)
}


