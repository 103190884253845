import React from 'react'
import '../CSS/Home.css'

function Home() {
    const today = new Date();
    const year = today.getFullYear();
    const age = year-2004;
    const ITEXP = year-2020;

    return (
        <>
        <div className="main_content">
            <div className="content_home">
                <div className="profile">
                    <samp>
                        👋 Salut, moi c'est <h1>Yann</h1>
                        <br></br>
                        <div className="description">
                            Je suis un développeur de {age} ans. <br/>
                            Avec mes {ITEXP} années d'expérience en informatique, je continue de créer et d'améliorer mes systèmes. <br/><br/>
                            <h3>Outils du quotidien</h3>
                            <a href="https://www.javascript.com/">JavaScript</a><br/>
                            <a href="https://nodejs.org/">Node.js</a><br/>
                            <a href="https://www.mysql.com/">MySQL</a><br/>
                            <a href="https://docs.microsoft.com/en-us/dotnet/csharp/">C#</a><br/>
                            	<br/>
                            <h3> Ce que je ne maîtrise pas encore parfaitement </h3>
                            	<a href="https://ejs.co/#docs">EJS</a><br/>
                                <a href="https://www.proxmox.com/">ProxMox</a><br/>
                                <a href="https://www.mongodb.com/">MongoDB</a>
                                <br/><br/>
                            <h3> J'aimerai bien m'y mettre prochainement </h3>
                                <a href="https://fr.wikipedia.org/wiki/C%2B%2B">C++</a><br/>
                            	<br/>
                        </div>
                        <br/><br/>
                        <h2>Plus d'informations à mon propos juste <a href="/about">ici</a>.</h2>
                        <br/>
                        <span>
                            Copyright © 2023-{year} SeenKid.Dev - Tous droits réservés.
                        </span>
                    </samp>
                </div>
            </div>
        </div>
        </>
    )
}

export default Home